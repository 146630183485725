body {
  margin: 0;
  box-sizing: border-box;
}

.pane {
  display: flex;
  background-color: rgba(0, 0, 0, 0.678);
}

.editor-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  margin: 0.5rem;
}

.editor-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #80aaff;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-family: sans-serif;
  font-weight: 700;
}
.editor-container.collapsed {
  flex-grow: 0;
  overflow: visible;
}

.expand-collapse-btn {
  margin-left: 0.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.fullscreen-toggler {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  right: 5px; 
  background-color: rgba(67, 62, 62, 0.549);
  color: white;
  padding: 7px;
  margin: 13px;
  outline: none;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.fullscreen-toggler:hover {
  background-color: rgba(182, 250, 248, 0.639);
  color: hsl(225, 6%, 13%);
}
.output {
  min-height: 44vh;
  margin: 5px;
}

.small-screen {
  display: none;
  font-family: sans-serif;
  color: white;
}

@media screen and (max-width: 600px) {
  .main {
      display: none;
  }

  .small-screen {
      display: block;
      padding: 20px;
      margin: 20px;
  }

  .small-screen #error-icon {
      height: 50px;
      width: 50px;
  }

  html {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
          url('https://images.unsplash.com/photo-1610661777342-b3d16eb867ca?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=376&q=80');
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }
}

.fullscreen-enabled {
  background-color: white;
}